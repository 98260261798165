import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosInstance from '../configs/axios';

export interface ChangePasswordProps {
  current_password: string;
  new_password: string;
  new_password_repeat: string;
  recaptcha: string;
}

export const changePassword = createAsyncThunk('appUser/changePassword', async (data: ChangePasswordProps) => {
  try {
    const response = await axiosInstance.post('/user/password-change', data);

    return { status: response.status, data: response.data };
  } catch (err) {
    // @ts-ignore
    return { status: err.response.status, error: err?.response?.data };
  }
});

export interface ResetPassword {
  url: string;
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}
export const forgotPassword = createAsyncThunk('appUser/forgotPassword', async (email: string) => {
  try {
    const response = await axiosInstance.post('/user/forgot-password', { email });

    return { status: response.status, data: response.data };
  } catch (err) {
    // @ts-ignore
    return { status: err?.response?.status, error: err?.response?.data };
  }
});

export const checkTokenForgotPassword = createAsyncThunk('appUser/forgotPassword', async (url: string) => {
  try {
    const response = await axiosInstance.get(`${url}`);

    return { status: response.status, data: response.data };
  } catch (err) {
    // @ts-ignore
    return { status: err?.response?.status, error: err?.response?.data };
  }
});

export const resetPassword = createAsyncThunk('appUser/forgotPassword', async ({ url, ...body }: ResetPassword) => {
  try {
    const response = await axiosInstance.post(`${url}`, body);

    return { status: response.status, data: response.data };
  } catch (err) {
    // @ts-ignore
    return { status: err?.response?.status, error: err?.response?.data };
  }
});

export const getHistoryTransactions = createAsyncThunk('appUser/getHistoryTransactions', async () => {
  const response = await axiosInstance.get(`/user/transactions`);
  return response.data;
});

export const appUserSlice = createSlice({
  name: 'appUser',
  initialState: {
    data: [],
  },
  reducers: {},
});

export default appUserSlice.reducer;
