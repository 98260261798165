const EyeIcon = () => (
  <svg width={20} height={16} viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.98701 0C7.02801 0 4.53501 1.45399 2.48701 3.84399C1.78601 4.66098 1.17301 5.534 0.675009 6.40599C0.373009 6.93399 0.189 7.34198 0.081 7.59398C-0.027 7.84498 -0.027 8.15499 0.081 8.40599C0.189 8.65799 0.373009 9.06598 0.675009 9.59398C1.17301 10.466 1.78601 11.339 2.48701 12.156C4.53501 14.546 7.02801 16 9.98701 16C12.946 16 15.439 14.546 17.487 12.156C18.188 11.339 18.801 10.466 19.299 9.59398C19.601 9.06598 19.785 8.65799 19.893 8.40599C20.001 8.15499 20.001 7.84498 19.893 7.59398C19.785 7.34198 19.601 6.93399 19.299 6.40599C18.801 5.534 18.188 4.66098 17.487 3.84399C15.439 1.45399 12.946 0 9.98701 0ZM9.98701 3.99999C12.196 3.99999 13.987 5.79099 13.987 7.99998C13.987 10.209 12.196 12 9.98701 12C7.77801 12 5.98701 10.209 5.98701 7.99998C5.98701 5.79099 7.77801 3.99999 9.98701 3.99999ZM9.98701 5.99999C8.88201 5.99999 7.98701 6.89499 7.98701 7.99998C7.98701 9.10498 8.88201 9.99998 9.98701 9.99998C11.092 9.99998 11.987 9.10498 11.987 7.99998C11.987 6.89499 11.092 5.99999 9.98701 5.99999Z'
      fill='currentColor'
    />
  </svg>
);

export default EyeIcon;
