import 'react-phone-input-2/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';

import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendGTMEvent } from '@next/third-parties/google';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import CloseIcon from '@/assets/svg/close-icon';
import EyeIcon from '@/assets/svg/eye-icon';
import Button from '@/common/button';
import { useAuth } from '@/hooks/use-auth';
import { bai_jamjuree } from '@/public/assets/fonts/Bai-Jamjuree';
import { CountryNamesPhone } from '@/utils/country-names-phone';
import { getCurrentLocale } from '@/utils/get-current-locale';

const defaultValues = {
  name: '',
  email: '',
  emailRepeat: '',
  phone: '',
  password: '',
  passwordRepeat: '',
  recaptcha: '',
};

interface FormData {
  name: string;
  email: string;
  phone?: string;
  password: string;
  passwordRepeat: string;
  recaptcha: string;
}

interface SignUpModalProps {
  closeModalSingUp: () => void;
  showModalSingIn?: () => void;
  otherRouterPushPage?: string;
}

export default function SignUpModal({ closeModalSingUp, otherRouterPushPage }: SignUpModalProps) {
  const auth = useAuth();
  const { user } = auth;
  const [isOpen, setIsOpen] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation('common');
  const [locale, setLocale] = useState<string | undefined>();
  const router = useRouter();

  const [phoneValue, setPhoneValue] = useState('');

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, t('login.mensage_error_invalid_name'))
      .required(t('login.mensage_error_empty_name'))
      .max(255),
    email: yup.string().email(t('login.mensage_error_valid_email')).required(t('login.mensage_error_empty_email')).max(255),
    emailRepeat: yup
      .string()
      .email(t('login.mensage_error_valid_email'))
      .required(t('login.mensage_error_empty_email'))
      .max(255)
      .oneOf([yup.ref('email')], t('login.mensage_error_repeat_email')),
    phone: yup.string(),
    password: yup.string().required(t('login.mensage_error_empty_password')).min(6, t('login.mensage_error_short_password')),
    passwordRepeat: yup
      .string()
      .required(t('login.mensage_error_empty_password'))
      .min(6, t('login.mensage_error_short_password'))
      .oneOf([yup.ref('password')], t('login.mensage_error_repeat_password')),
    recaptcha: yup.string().required(t('login.mensage_error_empty_captch')),
  });

  const closeModalCreateUser = useCallback(() => {
    setIsOpen(false);
    closeModalSingUp();
  }, [closeModalSingUp]);

  const {
    register,
    setError,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onBlur',
  });

  function handleLogin(data: FormData) {
    const { email, password, recaptcha } = data;
    auth.login({ login: email, password, recaptcha }, function () {
      setError('email', {
        type: 'manual',
        message: t('login.mensage_error_user_does_not_exist'),
      });

      if (otherRouterPushPage) {
        router.push(otherRouterPushPage);
      } else {
        router.push('/dashboard/overview');
      }
    });
  }
  useEffect(() => {
    if (user) {
      setIsOpen(false);
    }
  }, [user]);

  async function onSubmit(data: FormData) {
    const { name, email, phone, password, passwordRepeat, recaptcha } = data;
    try {
      await auth.create(
        {
          name,
          email,
          phone: phone ? Number(phone) : null,
          password,
          password_repeat: passwordRepeat,
          recaptcha,
          ref: null,
        },
        function handleCreateError({ response }) {
          // @ts-ignore
          window?.grecaptcha?.reset();
          // @ts-ignore
          const errorMessages = response?.data?.message;
          toast.error(typeof errorMessages === 'string' ? errorMessages : 'An error occurred while creating the user. Please try again!', {
            position: 'top-center',
          });
        },
        function handleCreateSuccess() {
          sendGTMEvent({ event: 'createAccount' });
          handleLogin(data);
        }
      );
    } catch (error) {
      toast.error('An error occurred while creating the user. Please try again!', {
        position: 'top-center',
      });
    }
  }

  function handlePhoneChange(event: React.ChangeEvent<HTMLInputElement>) {
    let input = event.target.value.replace(/\D/g, '');

    if (input.length > 10) {
      input = input.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (input.length > 6) {
      input = input.replace(/^(\d{2})(\d{4})(\d{0,4}).*/, '($1) $2-$3');
    } else if (input.length > 2) {
      input = input.replace(/^(\d{2})(\d{0,4}).*/, '($1) $2');
    } else if (input.length > 0) {
      input = input.replace(/^(\d*)/, '($1');
    }

    setPhoneValue(input);
    setValue('phone', input.replace(/\D/g, ''));
  }

  useEffect(function setLocaleEffect() {
    const getLocale = getCurrentLocale();
    const parts = getLocale.split('-');
    const country = parts.length > 1 ? parts[1] : parts[0];

    // @ts-ignore
    setLocale(country.toLocaleLowerCase());
  }, []);

  const country = CountryNamesPhone();

  const handleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const handleRecaptchaChange = useCallback(
    (token: string | null) => {
      setValue('recaptcha', token ?? '');
      clearErrors('recaptcha');
    },
    [setValue, clearErrors]
  );

  const handleRecaptchaExpired = useCallback(() => {
    setValue('recaptcha', '');
    setError('recaptcha', { type: 'required' });
  }, [setValue, setError]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={closeModalCreateUser}>
        <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-black/25' />
        </Transition.Child>
        <div className={`fixed inset-0 z-10 overflow-y-auto ${bai_jamjuree.className}`}>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 scale-95' enterTo='opacity-100 scale-100' leave='ease-in duration-200' leaveFrom='opacity-100 scale-100' leaveTo='opacity-0 scale-95'>
              <Dialog.Panel className='w-full max-w-[530px] overflow-hidden rounded-xl border border-[#515E6C]/55 bg-[#1D212A] p-10 text-left align-middle shadow-xl transition-all'>
                <div className='flex items-center justify-between'>
                  <Dialog.Title as='h3' className='text-2xl font-semibold leading-6 text-white'>
                    {t('login.create_account')}
                  </Dialog.Title>
                  <button onClick={closeModalCreateUser} className='text-lg font-semibold text-white hover:text-gray-800'>
                    <CloseIcon />
                  </button>
                </div>
                <div className='mt-4'>
                  <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-2.5'>
                    <div className='flex w-full items-center rounded-xl border border-[#879BAB] px-10 py-2.5 tablet:py-4'>
                      <input placeholder={t('login.placeholder.name')} type='string' {...register('name')} className={classNames('w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0')} />
                    </div>
                    {errors.name && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.name?.message}</span>}
                    <div className='flex w-full items-center rounded-xl border border-[#879BAB] px-10 py-2.5 tablet:py-4'>
                      <input placeholder={t('login.placeholder.email')} type='string' {...register('email')} className={classNames('w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0')} />
                    </div>
                    {errors.email && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.email?.message}</span>}
                    <div className='flex w-full items-center rounded-xl border border-[#879BAB] px-10 py-2.5 tablet:py-4'>
                      <input placeholder={t('login.placeholder.repeat_email')} type='string' {...register('emailRepeat')} className={classNames('w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0')} />
                    </div>
                    {errors.emailRepeat && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.emailRepeat?.message}</span>}
                    <div className='flex w-full items-center justify-start gap-12 rounded-xl border border-[#879BAB] px-10 py-2.5 tablet:py-4'>
                      <PhoneInput
                        country={locale}
                        localization={country}
                        enableSearch
                        searchNotFound={`${t('login.search_country_not_found')}`}
                        searchPlaceholder={`${t('login.search')}`}
                        i18nIsDynamicList
                        searchStyle={{
                          backgroundColor: '#1E293B',
                          color: '#fff',
                        }}
                        containerStyle={{
                          width: 'auto',
                          backgroundColor: '#D6E4EF',
                          color: '#D6E4EF',
                        }}
                        inputStyle={{
                          display: 'none',
                        }}
                        buttonStyle={{
                          backgroundColor: 'transparent',
                          border: 'none',
                        }}
                        dropdownStyle={{
                          backgroundColor: '#1E293B',
                          color: '#D6E4EF',
                        }}
                      />
                      <input placeholder={t('login.placeholder.phone')} type='tel' value={phoneValue} onChange={handlePhoneChange} className='w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0' />
                    </div>
                    {errors.phone && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.phone?.message}</span>}
                    <div className='flex w-full items-center rounded-xl border border-[#879BAB] px-10 py-2.5 tablet:py-4'>
                      <input placeholder={t('login.placeholder.password')} type={showPassword ? 'text' : 'password'} {...register('password')} className={classNames('w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0')} />
                      <span className='cursor-pointer text-[#515F6C] transition-all hover:opacity-70' onClick={handleShowPassword}>
                        <EyeIcon />
                      </span>
                    </div>
                    {errors.password && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.password?.message}</span>}
                    <div className='flex w-full items-center rounded-xl border border-[#879BAB] px-10 py-2.5 tablet:py-4'>
                      <input placeholder={t('login.placeholder.repeat_password')} type={showPassword ? 'text' : 'password'} {...register('passwordRepeat')} className={classNames('w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0')} />
                      <span className='cursor-pointer text-[#515F6C] transition-all hover:opacity-70' onClick={handleShowPassword}>
                        <EyeIcon />
                      </span>
                    </div>
                    {errors.passwordRepeat && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.passwordRepeat?.message}</span>}
                    <div className='mt-2 flex flex-col place-content-center gap-2.5'>
                      <div className='text-center'>
                        <div className='dispaly:inline-block'>
                          <ReCAPTCHA sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY ?? ''} onChange={handleRecaptchaChange} onExpired={handleRecaptchaExpired} />
                        </div>
                      </div>
                      {errors.recaptcha && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors.recaptcha?.message}</span>}
                    </div>
                    <div>
                      <Button type='submit' className='tablet:py-3' variant='gray' fullWidth disabled={isSubmitting}>
                        <span className='text-sm text-[#C8D4E2]'>{isSubmitting ? t('login.submitting') : t('login.button_sign_up')}</span>
                      </Button>
                    </div>
                  </form>
                  {router.pathname !== '/affiliates' && (
                    <div className='mt-5 tablet:mt-10'>
                      <h3 className='mb-2 text-2xl font-bold text-white tablet:mb-5'>{t('login.already_registered')}</h3>
                      <Button type='button' className='tablet:py-3' variant='primary' fullWidth onClick={closeModalCreateUser}>
                        <span className='text-sm'>{t('login.button_sign_in')}</span>
                      </Button>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
